import { useState, useEffect } from "react";

export const useClientAutocompleteIdObserver = (ref) => {
  const [refValue, setRefValue] = useState("");

  useEffect(() => {
    if (!ref.current) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "value") {
          const newValue = ref.current.value;
          setRefValue(newValue);
        }
      });
    });

    observer.observe(ref.current, {
      attributes: true,
      attributeFilter: ["value"]
    });

    return () => observer.disconnect();
  }, [ref]);

  return { refValue, setRefValue };
};
