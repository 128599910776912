import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import ShiftLogTimeSelector from "../shift_log_time_selector";
import ClientSearchAutoComplete from "../client_search_autocomplete";
import { useTableViewContext } from "./TableViewContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { RefreshShiftLogsContext } from "./RefreshShiftLogsContext";
import ShiftLogSeenCheckbox from "../shift_log_seen_checkbox";
import ShiftLogFormHospice from "./shift_log_form_hospice";

function ShiftLogForm() {
  const { state, dispatch } = useTableViewContext();
  const [hospiceView, setHospiceView] = useState("");

  const [showClientSearchBox, setShowClientSearchBox] = useState(false);
  const isNewForm = state.action === "new";

  const [clientAutocompletedId, setClientAutocompletedId] = useState(state.selectedClientId);
  const { pageParams } = state;

  const handleSeenChange = (newIsSeen) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      seen: newIsSeen
    }));
  };

  const refreshShiftLog = useContext(RefreshShiftLogsContext);
  const { loading, data: shiftLog } = useAsyncCallback(async () => {
    if (isNewForm) {
      return await getNewShiftLog();
    }
    return await getShiftLogById(state.selectedShiftLog.id);
  });
  useEffect(() => {
    if (!loading && shiftLog) {
      if (isNewForm) {
        setFormValues({
          ...shiftLog,
          shift_num: state.selectedRowShiftNum
        });
      } else {
        setFormValues(shiftLog);
      }
    }
  }, [loading]);
  const [formValues, setFormValues] = useState(null);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // Check if any required input or textarea is not filled
      const form = document.getElementById("shift-log-popup-form");
      const requiredInputs = form.querySelectorAll("input[required], textarea[required]");
      const emptyFields = [];

      requiredInputs.forEach((input) => {
        if (!input.value.trim()) {
          emptyFields.push(
            input.name
              .replace("_text", "")
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          );
        }
      });

      if (emptyFields.length > 0) {
        for (const field of emptyFields) {
          toastr.error(`Please fill in the required field: ${field}`);
        }
        return;
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
      const url = isNewForm ? "/shift_logs.json" : `/shift_logs/${state.selectedShiftLog.id}.json`;
      const method = isNewForm ? "POST" : "PATCH";
      await axios({
        method: method,
        url: url,
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        },

        data: {
          shift_log: formValues,
          client_autocomplete_id: clientAutocompletedId,
          date: pageParams.date || state.selectedShiftLog?.log_date || state.selectedDateForNewLog,
          location:
            pageParams.location ||
            state.selectedShiftLog?.location ||
            state.selectedClientDetail.location,
          client: state.selectedClientName
        }
      });
      await refreshShiftLog();

      dispatch({ type: "reset" });
    } catch (error) {
      const result = error?.response?.data;
      if (result && result.base) {
        const errorMessages = result.base.join(", ");
        toastr.error(errorMessages || "Something went wrong");
      } else if (result && result.message) {
        toastr.error(result.message || "Something went wrong");
      } else {
        toastr.error("Something went wrong");
      }
    }
  };

  if (loading || !formValues) {
    return <div>loading...</div>;
  }
  return (
    <div className="shift-log-form-popup container">
      <h1 className="title" style={{ color: "grey" }}>
        {formValues.client_name || state.selectedClientName}
      </h1>
      <div className="shift-log-form-popup-header">
        <h1 className="title" style={{ color: "black" }}>
          {isNewForm ? "New Shift Log: " : "Edit Shift Log:"}
        </h1>
        {shiftLog.location === "Diane Morrison Hospice" && (
          <HospiceViewOptions hospiceView={hospiceView} setHospiceView={setHospiceView} />
        )}
      </div>

      <form
        id="shift-log-popup-form"
        className="form-box"
        style={{
          marginTop: "0px"
        }}
        onSubmit={handleSubmit}
      >
        <div className="shift-log-page">
          <div className="client-action">
            {isNewForm && (
              <>
                <label className="label time-title">Time</label>
                <div className="time-select">
                  <ShiftLogTimeSelector
                    hour={formValues.hour}
                    minute={formValues.minute}
                    am_pm={formValues.am_pm}
                  />
                </div>
                {!state.selectedClientName && (
                  <>
                    <label className="label check-title">
                      Please fill in if associated to client
                    </label>
                    <ClientSearchAutoComplete
                      name="shift_log[client_name]"
                      selected_client_id={clientAutocompletedId}
                      set_selected_client_id={setClientAutocompletedId}
                      className="is-rounded"
                    />
                    <div className="check-desc">
                      <span className="form-autocomplete-note">
                        Choose registered clients from dropdown
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
            {!isNewForm && (
              <>
                <label className="label time-title">Time of incident</label>
                <div className="time-select">
                  <ShiftLogTimeSelector
                    hour={formValues.hour}
                    minute={formValues.minute}
                    am_pm={formValues.am_pm}
                  />
                </div>
                {/* ToDo: Updating client doesn't work. Disabling and adding it in the list of tasks */}
                {false && (
                  <>
                    <label className="label check-title">Check to update client</label>
                    <div className="check-select">
                      <input
                        type="checkbox"
                        name="client_name_checked"
                        value={showClientSearchBox}
                        onChange={() => setShowClientSearchBox(!showClientSearchBox)}
                        className="checkbox shift-logs-client-update-checkbox client-associate"
                      />
                      {showClientSearchBox && (
                        <div className="client-search-bar match-client">
                          <ClientSearchAutoComplete
                            name="shift_log[client_name]"
                            selected_client_id={clientAutocompletedId}
                            set_selected_client_id={setClientAutocompletedId}
                            className="is-rounded"
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
                {showClientSearchBox && (
                  <div className="check-desc">
                    <span className="form-autocomplete-note match-client">
                      Choose registered clients from dropdown
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="shift-num-form">
            <label htmlFor="shift_num">Shift Number:</label>
            <select
              name="shift_num"
              value={formValues.shift_num}
              onChange={(e) => {
                setFormValues({ ...formValues, shift_num: e.target.value });
              }}
              className="time-select"
            >
              {shiftNumOptions.map((shiftNumOption, index) => {
                return (
                  <option key={index} value={shiftNumOption}>
                    {shiftNumOption}
                  </option>
                );
              })}
            </select>
          </div>
          {shiftLog.location === "Diane Morrison Hospice" && (
            <div className="log-rows-category">
              <h3>Hygiene</h3>
            </div>
          )}
          <div className="log-rows-form">
            {shiftLogFormFields.map((shiftLogFormField, index) => (
              <Fragment key={`${index} form fields`}>
                <label className="checkbox_container">
                  <input
                    type="checkbox"
                    name={shiftLogFormField.key}
                    className="checkbox"
                    checked={formValues[shiftLogFormField.key]}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormValues({
                        ...formValues,
                        [shiftLogFormField.key]: isChecked,
                        [`${shiftLogFormField.key}_text`]: isChecked
                          ? formValues[`${shiftLogFormField.key}_text`]
                          : ""
                      });
                    }}
                  />
                  {shiftLogFormField.label}
                </label>

                <div className="input_container">
                  {formValues[shiftLogFormField.key] && (
                    <>
                      {shiftLogFormField.key === "vitals" ? (
                        <textarea
                          type="text"
                          name={`${shiftLogFormField.key}_text`}
                          placeholder={`${shiftLogFormField.label} Notes`}
                          value={formValues[`${shiftLogFormField.key}_text`]}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [`${shiftLogFormField.key}_text`]: e.target.value
                            });
                          }}
                          className="input"
                          required
                        />
                      ) : (
                        <input
                          required
                          type="text"
                          name={`${shiftLogFormField.key}_text`}
                          placeholder={`${shiftLogFormField.label} Notes`}
                          value={formValues[`${shiftLogFormField.key}_text`]}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [`${shiftLogFormField.key}_text`]: e.target.value
                            });
                          }}
                          className="input"
                        />
                      )}
                    </>
                  )}
                </div>
              </Fragment>
            ))}
          </div>
          {shiftLog.location === "Diane Morrison Hospice" && (
            <ShiftLogFormHospice
              formValues={formValues}
              setFormValues={setFormValues}
              shiftLog={shiftLog}
              hospiceView={hospiceView}
            />
          )}

          <div className="med-check">
            <div>Meds Given As Prescribed?</div>
            <div>
              <input
                type="checkbox"
                name="medication"
                className="checkbox med-true"
                checked={formValues.medication}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    medication: e.target.checked,
                    medication_text: ""
                  });
                }}
              />
              Yes
            </div>
            <div>
              <input
                type="checkbox"
                name="medication"
                className="checkbox med-false"
                checked={!formValues.medication}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    medication: !e.target.checked,
                    medication_text: ""
                  });
                }}
              />
              No
            </div>
            {!formValues.medication && (
              <input
                type="text"
                name="medication_text"
                placeholder="Why Not?"
                value={formValues.medication_text}
                onChange={(e) => {
                  setFormValues({ ...formValues, medication_text: e.target.value });
                }}
                className="input med-text"
              />
            )}
          </div>
          <div>
            <ShiftLogSeenCheckbox isSeen={formValues.seen} onChange={handleSeenChange} />
          </div>
          <label className="label">What happened?</label>
          <textarea
            name="log_description"
            value={formValues.log_description}
            onChange={(e) => {
              setFormValues({ ...formValues, log_description: e.target.value });
            }}
            className="input client-text"
          />

          <div className="shift-log-action">
            {isNewForm && (
              <button type="submit" className="button is-primary" onClick={handleSubmit}>
                Create Shift Log
              </button>
            )}
            {!isNewForm && (
              <button type="submit" className="button is-primary" onClick={handleSubmit}>
                Update Shift Log
              </button>
            )}
            <button
              type="button"
              className="button is-light"
              onClick={() => {
                dispatch({
                  type: "reset"
                });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ShiftLogForm;

const shiftNumOptions = ["1st", "2nd", "3rd"];

const shiftLogFormFields = [
  {
    key: "meal_prep",
    label: "Meal Prep"
  },
  {
    key: "laundry",
    label: "Laundry"
  },
  {
    key: "goods",
    label: "Good Distributions"
  },
  {
    key: "bed_checks",
    label: "Bed Checks"
  },
  {
    key: "personal_care",
    label: "Personal Care"
  },
  {
    key: "room_cleaning",
    label: "Room Cleaning"
  },
  {
    key: "shower",
    label: "Shower"
  },
  {
    key: "shave",
    label: "Shave"
  },
  {
    key: "hair_wash",
    label: "Hair Wash"
  },
  {
    key: "hair_cut",
    label: "Hair Cut"
  },
  {
    key: "peri_care",
    label: "Peri Care"
  },
  {
    key: "nail_care",
    label: "Nail Care"
  },
  {
    key: "bed_linen_change",
    label: "Bed Linen Change"
  },
  {
    key: "clothing_change",
    label: "Clothing Change"
  },
  {
    key: "vitals",
    label: "Vitals"
  }
];

async function getShiftLogById(shiftLogId) {
  const response = await axios.get(`/shift_logs/${shiftLogId}.json`);
  return response.data;
}

async function getNewShiftLog() {
  const response = await axios.get("/shift_logs/new.json");
  return response.data;
}

const HospiceViewOptions = ({ hospiceView, setHospiceView }) => {
  const handleDefaultClick = (e) => {
    e.preventDefault();
    setHospiceView("");
  };

  const handleOriginalClick = (e) => {
    e.preventDefault();
    setHospiceView("original");
  };

  return (
    <div className="">
      <a href="#" onClick={handleDefaultClick}>
        <button
          id="switch_default_view_btn"
          className={`button ${hospiceView !== "original" ? "is-active" : ""}`}
        >
          Default
        </button>
      </a>
      <a href="#" onClick={handleOriginalClick}>
        <button
          id="switch_original_view_btn"
          className={`button ${hospiceView === "original" ? "is-active" : ""}`}
        >
          Original
        </button>
      </a>
    </div>
  );
};

HospiceViewOptions.propTypes = {
  hospiceView: PropTypes.string,
  setHospiceView: PropTypes.func
};
