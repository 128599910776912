import React, { useState, useEffect, useMemo, useRef } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import PropTypes from "prop-types";
import NativeElementListener from "./native_element_value_listener";
import { useClientAutocompleteIdObserver } from "../hooks/useClientAutocompleteIdObserver";

const guestOption = { label: "The report is for guest", value: "GUEST", id: "GUEST" };
const Input = (props) => {
  const { autoComplete } = props.selectProps;
  return (
    <components.Input
      {...props}
      explorator_test_id={"client-page-autosearch-input-element"}
      autoComplete={autoComplete}
    />
  );
};

Input.propTypes = {
  selectProps: PropTypes.object.isRequired
};

const ClientSearchAutoComplete = (props) => {
  const {
    name,
    selected_client_name,
    selected_client_id,
    is_client_pages,
    is_users_active = "all",
    show_unknown_client_option: showUnknownClientOption,
    show_the_report_is_for_guest: showTheReportIsForGuest,
    is_for_guest: isForGuestSelected
  } = props;

  const [selectedClientName, setSelectedClientName] = useState(
    showUnknownClientOption ? "Unknown Client" : ""
  );
  const [selectedClientId, setSelectedClientId] = useState(selected_client_id);
  const [allClients, setAllClients] = useState([]);
  const clientAutocompleteIdRef = useRef(null);

  // This is only used when listen_location_change_input_id is passed in.
  const [location, setLocation] = useState("");

  const onSelectRedirectToSpecificClient = (id) => {
    if (is_client_pages) {
      window.location = `/clients/${id}`;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadClientsAutoCompleteList();
  }, []);

  const loadClientsAutoCompleteList = () => {
    setIsLoading(true);
    const fetchUrl = `/clients/client_autocomplete_authorized_clients.json?all_clients=${
      is_client_pages ? "true" : "false"
    }`;
    axios
      .get(fetchUrl)
      .then((response) => {
        let filteredClients = response.data.filter((client) => {
          if (is_users_active === "true" && !is_client_pages) return !client.inactive;
          if (is_users_active === "false" && !is_client_pages) return client.inactive;
          if (!is_users_active || is_users_active === "all" || is_client_pages) return true;

          return false;
        });

        setAllClients(filteredClients);
      })
      .catch(() => {
        setIsLoading(false);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const options = useMemo(() => {
    const createClientOption = (client) => {
      let clientName = `${client.first_name} ${client.last_name}`;
      let location = client.location;
      let inactive = client.inactive;
      let ignored = client.ignored;
      let label = clientName;
      if (location && is_client_pages) {
        label += ` (${location})`;
      }
      if (inactive && is_client_pages) {
        label += " - Inactive";
      }
      if (ignored && is_client_pages) {
        label += " ---Ignored---";
      }
      return {
        label: label,
        value: clientName,
        id: client.id,
        location: location,
        inactive: inactive
      };
    };

    const clientsToDisplay = location
      ? allClients.filter((client) => client.location === location)
      : allClients;

    const clientOptions = clientsToDisplay.map(createClientOption);

    if (showTheReportIsForGuest) {
      clientOptions.push(guestOption);
    }

    if (showUnknownClientOption) {
      clientOptions.push({ label: "Unknown Client", value: "", id: "" });
    }

    return clientOptions;
  }, [allClients, location, showUnknownClientOption]);

  const selectClient = useMemo(() => {
    if (showUnknownClientOption && !selectedClientId) {
      return null;
    }
    if (selectedClientId === guestOption.id) {
      return guestOption;
    }
    if (selectedClientId) {
      return allClients.find((allClient) => +allClient.id === +selectedClientId);
    }

    return null;
  }, [selectedClientId, allClients]);

  useEffect(() => {
    if (clientAutocompleteIdRef.current) {
      clientAutocompleteIdRef.current.value = selectClient ? selectClient.id : "";
    }
  }, [selectClient, selectedClientId]);

  const { refValue: clientAutocompleteIdValue } =
    useClientAutocompleteIdObserver(clientAutocompleteIdRef);

  useEffect(() => {
    if (clientAutocompleteIdValue) {
      const clientId = clientAutocompleteIdValue;
      const selectedOption = options.find((option) => String(option.id) === String(clientId));
      if (!selectedOption) {
        return;
      }

      setSelectedClientName(selectedOption?.label || "");
      setSelectedClientId(selectedOption?.id || "");
      onSelectRedirectToSpecificClient(selectedOption?.id || "");
    }
  }, [clientAutocompleteIdValue, options]);

  useEffect(() => {
    if (selected_client_name) {
      setSelectedClientName(selected_client_name);
    }
    if (showTheReportIsForGuest && isForGuestSelected) {
      setSelectedClientName(guestOption.label);
    }
  }, []);

  const defaultStyles = {
    option: (styles) => ({
      ...styles,
      cursor: "pointer"
    })
  };

  const clientPageStyles = {
    ...defaultStyles,
    valueContainer: (styles) => ({
      ...styles,
      height: "3em",
      display: "flex",
      alignItems: "center"
    }),
    menu: (base) => ({
      ...base,
      width: "40rem"
    })
  };

  return (
    <div className="client-search-autocomplete">
      {props.listen_location_change_input_id && (
        <NativeElementListener
          listenId={props.listen_location_change_input_id}
          setFieldValue={setLocation}
        />
      )}
      <Select
        key={`${location}`}
        styles={is_client_pages ? clientPageStyles : defaultStyles}
        components={{ Input }}
        options={options}
        isLoading={isLoading}
        isClearable={true}
        value={{ label: selectedClientName, value: selectedClientName }}
        onChange={(selectedOption) => {
          setSelectedClientName(selectedOption?.label || "");
          setSelectedClientId(selectedOption?.id || "");
          onSelectRedirectToSpecificClient(selectedOption?.id || "");
        }}
        name={name}
        noOptionsMessage={() => (error ? "Error in fetching clients" : "No options")}
      />
      <input
        data-explorator_test_id="todo_list_new_page_client_name_"
        ref={clientAutocompleteIdRef}
        name={"client_autocomplete_id"}
        type={"hidden"}
      />
      <HiddenInput showUnknownClientOption selectClient={selectClient} />
    </div>
  );
};

const HiddenInput = ({ selectClient }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const value = selectClient ? JSON.stringify(selectClient) : "";
    if (inputRef.current) {
      inputRef.current.value = value;
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  }, [selectClient]);

  /* The hidden input fields are created so other native components can get the selected client data. */
  return <input id="client_autocomplete_selected_client" type="hidden" ref={inputRef} />;
};

export default ClientSearchAutoComplete;

Input.propTypes = {
  selectProps: PropTypes.object.isRequired
};

ClientSearchAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  selected_client_name: PropTypes.string,
  selected_client_id: PropTypes.string,
  is_client_pages: PropTypes.bool,
  is_users_active: PropTypes.string,
  listen_location_change_input_id: PropTypes.string,
  show_unknown_client_option: PropTypes.bool,
  show_the_report_is_for_guest: PropTypes.bool,
  is_for_guest: PropTypes.bool
};

HiddenInput.propTypes = {
  selectClient: PropTypes.object || null
};
